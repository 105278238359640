import React from 'react'
import '../../style/component.less';
import fetch from '../../fetch/axios';
import { Radio, message } from 'antd';

class HeaderUsage extends React.Component {
  state = {
    GPT: {},
    GPT_4_128: {},
    DallE: {},
    GPT_4_V: {},
    type: "GPT",
    show: true,

    options: {
      GPT: {
        fileString: "",
        fileType: [],
        fileNum: 0,
      },
      GPT_4_128: {
        fileString: ".pdf,.doc,.docx,.xlsx,.xls",
        fileType: [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ],
        fileNum: 99,
      },
      DallE: {
        fileString: "",
        fileType: [],
        fileNum: 0,
      },
      GPT_4_V: {
        fileString: ".jpg,.jpeg,.png,.gif",
        fileType: [
          "image/jpg",
          "image/jpeg",
          "image/png",
          "image/gif",
        ],
        fileNum: 99,
      },
    },

    chatEngine: "", //聊天所使用的引擎
  }
  componentDidMount() {
    this.changeType({target: {value: 'GPT_4_128'}}, true);
    // 绑定用量方法
    // if (this.props?.showWidth ===768 && document.body.clientWidth < 768) {
    //   // 屏幕宽度小于768px时
    //   this.setState({show: true})
    //   this.props?.setCheckUsage(this.checkUsage.bind(this));
    //   this.checkUsage();
    // } else if (this.props?.showWidth >768 && document.body.clientWidth > 768) {
      // 屏幕宽度大于768px时
      this.setState({show: true})
      this.props?.setCheckUsage(this.checkUsage.bind(this));
      this.checkUsage();
    // }
  }

  static getDerivedStateFromProps(props, state) {
    // console.log(props)
    if (props.reducer.used.type !== state.type) {
      return {
      }
    }
    return null
  }
  componentDidUpdate() {
    if (this.props.reducer.used.type !== this.state.type) {
      // console.log("componentDidUpdate", this.props.reducer.used.type)
      this.setState({type: this.props.reducer.used.type})
    }
    let engine = this.props.reducer.chatInfo?.chatObj?.engine;
    if (engine === 'GPT_4') {
      engine = 'GPT_4_128';
    }
    if ( engine !== this.state.chatEngine) {
      this.setState({ chatEngine: engine }, ()=>{
        if (!!engine) {
          this.changeType({target: {value: engine}}, true);
        }
      })
    }
  }
  
  checkUsage() {
    fetch.get(`/api/chat/GPTUsage`).then((res) => {
      if (res) {
        let usageArr = res.data;
        usageArr?.forEach((item) => {
          if (item.gpt_model === "GPT") {
            this.setState({GPT: item})
          } else if (item.gpt_model === "GPT_4_128") {
            this.setState({GPT_4_128: item})
          } else if (item.gpt_model === "DallE") {
            this.setState({ DallE: item })
          } else if (item.gpt_model === "GPT_4_V") {
            this.setState({ GPT_4_V: item })
          }
        })
      }
    })
  }
  changeType(e, isInit) {
    // return;
    let chatObj = this.props.reducer.chatInfo?.chatObj ;
    if (chatObj?.subjectGUID && !isInit) {
      return message.warning(this.props.t('usage.Please create a new session and try again to switch models'));
    }

    // console.log(e.target.value)

    this.setState({type: e.target.value})
    // console.log(this.props)
    this.props?.setUsage({
      type: e.target.value,
      fileType: this.state.options[e.target.value]?.fileType || [],
      fileString: this.state.options[e.target.value]?.fileString || "",
      fileNum: this.state.options[e.target.value]?.fileNum || 0,
    })

    // 切换主题 （如果可用）
    const { theme } = this.props.reducer;
    if (e.target.value === 'GPT' && theme.GPT) {
      this.props.setThemeData(theme.GPT);
    }
    if (e.target.value === 'GPT_4' && theme.GPT4) {
      this.props.setThemeData(theme.GPT4);
    }
  }

  render() {
    const { t } = this.props;
    const role = this.props.reducer.user.role;
    const chatObj = this.props.reducer.chatInfo?.chatObj;
    // console.log(!!chatObj.subjectGUID)
    return (
      <div className={`usage_box ${this.props.className||""}`}>
        {this.state.show ?
          <div className='header_usage'>
            <div className='hu_title'>{t('usage.Usage')}</div>
            <div className='hu_text'>{this.state[this.state.type]?.token_count || "0"} / {this.state[this.state.type]?.total_count || "0"} {t('usage.token')}</div>
          </div>
          : null}
        <div className='header_divider'></div>
        <div className='header_type'>
          <div className='ht_title'>{t('usage.Configuration')}</div>
          <div className='ht_select'>
            <Radio.Group onChange={(e) => { this.changeType(e) }} value={this.state.type}>
              {/* <Radio value={"GPT"} disabled={!!chatObj.subjectGUID}>{t('usage.ChatGPT')}</Radio> */}
              {(role?.includes("administrator") || role?.includes("supervisor") || role?.includes("User with advanced features") || role?.includes("user")) &&
                <>
                  <Radio value={"GPT_4_128"} disabled={!!chatObj.subjectGUID}>{t('usage.GPT_4_128')}</Radio>
                  <Radio value={"DallE"} disabled={!!chatObj.subjectGUID}>{t('usage.DallE')}</Radio>
                  <Radio value={"GPT_4_V"} disabled={!!chatObj.subjectGUID}>{t('usage.GPT-4')}</Radio>
                </>
              }
            </Radio.Group>
          </div>
        </div>
      </div>
    )
  }
}

export default HeaderUsage