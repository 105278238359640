import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../redux/actions'
import { bindActionCreators } from 'redux'
import Nav from './components/nav'
import Header from './components/header'
import { withRouter } from 'react-router-dom'
import fetch from '../fetch/axios'
import { Layout, ConfigProvider } from 'antd';
import '../style/global.less';
import '../style/app.less';
import Chat from "./chat/chat";
import { withTranslation } from "react-i18next"
import { ApplicationPaths, QueryParameterNames } from '../components/api-authorization/ApiAuthorizationConstants';
import authService from '../components/api-authorization/AuthorizeService';

import uaParser from 'ua-parser-js'
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';

const { Content } = Layout;

class App extends React.Component {
  state = {
    userinfo: {},
    isEmbed: false,
    chatObj: {
      subjectGUID: "",
    },
    pId: "",
    isChecking: false,
    isAuthenticated: true,
    authenticated: false,
    security: true, // 是否显示安全提示框
  }

  componentDidMount() {
    // 清除二级域名下的Authorization
    document.cookie = `Authorization=;domain=${window.location.host};expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    // 切换主题
      this.props.setThemeData('xingI');
    // 判断路由
    if (window.location.hash.includes('#/chat/')) {
      // 识别bot信息
      this.setState({ isEmbed: true }, () => {
        this.setState({ chatObj: {} })
      });
      let params = new URLSearchParams(window.location.hash.split('?')[1]);
      let pId = params.get('pId');
      this.setState({ pId })
    } else {
      //}

      // // 存储用户ID
      // if (window.location.hash.includes("#/login?")) {
      //   let uid = this.getParams('uid');
      //   if (uid) {
      //     window.localStorage.setItem('uid', uid)
      //     // 获取token
      //     this.getToken(uid, () => {
      //       this.props.history.push("/chat-official");
      //     });
      //   } else {
      //     this.props.userLogOut("redirect");
      //   }
      // } else {
      //   let uid = window.localStorage?.getItem('uid');
      //   if (uid) {
      //     this.getToken(uid);
      //   } else {
      //     this.checkLogin();
      //   }
      // }

      // // 每四分钟刷新
      // setInterval(() => {
      //   this.checkLogin();
      // }, 9 * 60 * 1000);
      this.unlisten = this.props.history.listen((location, action) => {
        // console.log('Route changed!', location.pathname, action);
        // 在这里可以执行一些路由变化时的操作
        if (location.pathname !== "/authentication/login") { 
          this.getAuthNew();
        }
      });
      if (!window.location.href.includes("/authentication/login")) { 
        this.getAuthNew();
      }
    }

    // 设置页面字体大小
    this.setFontSize(16);
    // this.getAuth();
    this.getUser();
  }

  getAuthNew() {
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
    fetch.post(`/api/auth/check`).then(res => {
      // 调通即为成功
      this.setState({ authenticated: true })
    }).catch(err => {
      // 失败则跳转到登录页
      this.setState({ authenticated: false })
      this.props.history.push(redirectUrl);
    })
  }
  async getAuth() {
    // debugger;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
    const authenticated = await authService.isAuthenticated();
    // const authenticated = true;

    console.log("redirectUrl - kaka:", redirectUrl)
    if (!authenticated) {
      return this.props.history?.push(redirectUrl);
    }
    this.setState({ authenticated });
  }
  async getUser() {
    let user = await authService.getUser();
    console.log("user:", user)
    // user = {
    //   amr: ['pwd'],
    //   auth_time: 1689297241,
    //   email: "admin@domain.com",
    //   idp: "local",
    //   role: ['administrator', 'user'],
    //   s_hash: "-PKjgEnTVwomTj6tdHMMmg",
    //   sid: "mP3IlpZ6L9dkh0_aFYI9gw",
    //   sub: "0b9f54d8-0659-43aa-892f-c45499152e8c",
    // }
    this.setUserInfo(user);
  }

  // 兼容性检查
  getHashPriorty = () => {
    let ua = uaParser(navigator.userAgent)
   
    const { browser } = ua
    let major = browser.version?.split('.')?.[0] - 0;
    if (browser.name === 'Chrome' && major > 88) {
      return 'low'
    } else if (browser.name === 'Firefox' && major > 78) {
      return 'low'
    } else if (browser.name === 'Safari' && major > 14) {
      return 'low'
    } else {
      return 'high'
    }
  }

  // 动态设置字体大小
  setFontSize(size) {
    // 获取当前屏幕宽度
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    // 计算根元素的字体大小，例如假设 1rem 等于 10px
    const rootFontSize = screenWidth / 375 * 10; // 375 是设计稿宽度
    // 设置根元素的字体大小
    document.documentElement.style.fontSize = `${size || rootFontSize}px`;
  }

  getParams(str) {
    const params = new URLSearchParams(window.location.hash.split('?')[1]);
    return params.get(str);
  }

  getGuid() {
    return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  }

  //获取token
  getToken(uid, callback) {
    let obj = {
      contactId: uid,
      password: '84976492'
    };
    fetch.post(`/Auth/Login`, obj).then(res => {
      // console.log(res)
      if (res && res.success) {
        this.setUserInfo(res.data)
        this.setState({ isAuthenticated: true })
      } else {
        console.log(res.message);
      }
      return callback ? callback() : undefined;
    }).catch(err => {
      // console.log(err)
      // 退出登陆
      // this.props.userLogOut("redirect");
    })
  }

  // 校验登录 更新token
  checkLogin() {
    if (this.state.isChecking) return;
    this.setState({ isChecking: true })
    fetch.post(`/Auth/refresh_token`).then(res => {
      // console.log(res)
      if (res && res.success) {
        this.setUserInfo(res.data)
      }
      this.setState({ isChecking: false, isAuthenticated: true })
    }).catch(err => {
      // console.log(err)
      // 退出登陆
      this.props.userLogOut("redirect")
    })
  }

  // 设置用户信息
  setUserInfo(user) {
    if (!user) {
      user = {};
    };
    let userName = '';
    let shortName = '';
    if (user.firstName) {
      userName = user.firstName;
      shortName = user.firstName.slice(0, 1);
    }
    if (user.lastName) {
      userName += userName ? ' ' : '' + user.lastName;
      shortName += user.lastName.slice(0, 1);
    }
    if (!user.firstName && !user.lastName) {
      userName = user.email?.split('@')[0] || "";
      shortName = user.email?.slice(0, 1) || "";
    }
    user.name = userName;
    user.shortName = shortName;
    this.props.setUserData(user);
    // this.props.setPlanData(user.pricingPlan || 0);
    // this.setCookie('Authorization', user.token, 6);
    // localStorage.setItem("token", user.token);
    localStorage.setItem("mailbox", user.email);
  }

  setCookie(key, value, hour) {
    var cookie = key + '=' + encodeURIComponent(value);
    if (hour > 0) {
      var date = new Date();
      date.setTime(date.getTime() + hour * 3600 * 1000);
      // 设置作用域名
      // 判断当前域名是否为顶级域名
      let domain = window.location.hostname.split('.');
      if (domain.length > 2) {
        cookie += ';domain=' + domain[1] + '.' + domain[2];
      } else if (domain.length > 1) {
        cookie += ';domain=' + domain[0] + '.' + domain[1];
      }
      cookie += ';expires=' + date;
    }
    document.cookie = cookie;
  }


  render() {
    const { theme } = this.props.reducer;
    // console.log(theme)
    return (
      <div className="app">
        <StyleProvider
          hashPriority={this.getHashPriorty()}
          transformers={[legacyLogicalPropertiesTransformer]}
        >
          <ConfigProvider
            // locale={this.props.language === 'zh' ? require('antd/es/locale/zh_CN').default : require('antd/es/locale/en_US').default}
            theme={{
              token: {
                colorPrimary: theme?.['--primary-color'] || '#854DFF',
                fontFamily: theme?.['--font-family'] || '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
              },
            }}
          >
            {this.state.isEmbed ?
              <Chat
                {...this.props}
                chatObj={this.state.chatObj}
                pId={this.state.pId}
                projectType={'File'}
                responeseType={'text'}
              ></Chat>
              : this.state.isAuthenticated && this.state.authenticated ?
                <Layout>
                  <Nav {...this.props}></Nav>
                  <Layout className="site-layout">
                    <Header {...this.props}></Header>
                    <Content className='app_content'>
                      {/* <Welcome></Welcome> */}
                      {this.props.children}
                    </Content>
                  </Layout>
                </Layout>
                : null
            }
          </ConfigProvider>
        </StyleProvider>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return state.reducer
}
const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(actions, dispatch)
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App)))
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App)))