import React, { Component } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { message } from 'antd';
import '../../style/component.less';

// 父组件调用子组件方法
// fViewRef = React.createRef();
// <FilePreview ref={this.fViewRef}></FilePreview>
// this.fViewRef.current.getFile(id,name);

class FilePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewOpen: false,
      fileUrl: null,
      isGetting: false,
      currentyProgressGuid: null,
      mobile: false
    }
  }

  componentDidMount() {
    
  }

  getCookie(name) {
    let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    arr = document.cookie.match(reg)
    return arr ? unescape(arr[2]) : null;
  }

  // 获取唯一guid
  getGuid() {
    return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c === 'x' ? r : ((r&0x3)|0x8);
      return v.toString(16);
    });
  }

  getFile(fileId, fileName) {
    let fId = fileId;
    let fName = fileName || `${new Date().getTime()}.pdf`;
    let mobile = navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i) || window.innerWidth < 768
    let pGuid = this.getGuid();
    // 判断是否是移动端
    console.log(mobile)
    if (!mobile && (this.state.isGetting || !fId) ) return;
    this.setState({
      isGetting: true,
      viewOpen: mobile? false:true,
      fileUrl: null,
      mobile,
      currentyProgressGuid: pGuid
    });
    const requestOptions = {
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'bearer ' + this.getCookie('Authorization') || localStorage.getItem("token"),
      },
    }; 
    fetch(`/ProjectFile/Download/${fId}`, requestOptions).then(response => response.blob()).then(res => {
      if (res) {
        // 判断进程id是否和当前id一致
        if (pGuid !== this.state.currentyProgressGuid) return;

        const blob = new Blob([res], {type: 'application/pdf', fileName: fName});
        const fileUrl = URL.createObjectURL(blob);
        // console.log(fileUrl)
        // 判断是否是移动端
        if (mobile) {
          // 下载blob文件
          const a = document.createElement('a');
          a.download = fName;
          a.href = fileUrl;
          a.click();
        } else {
          this.setState({ fileUrl });
        }
        this.setState({ isGetting: false });
      } else {
        message.error('file not found');
      }
    }).catch(error => {
      console.log(error);
      this.setState({ isGetting: false });
    });
  }


  // 修复移动端显示黑框的bug

  closePrev() {
    this.setState({ viewOpen: false, isGetting: false });
  }

  render() {
    return (
      <>
      {this.state.viewOpen ?
        <div className='file_view' onClick={()=>{ this.closePrev() }}>
          <div className='fv_bg'><CloseCircleOutlined /></div>
          {this.state.fileUrl ?
            <iframe title='preview_file' src={this.state.fileUrl}></iframe>:
            <LoadingOutlined className='loading'/>
          }
        </div>
        :null
      }
      </>
    );
  }
}

export default FilePreview;
