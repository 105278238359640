import icon from "./icon";
import logo from "../../images/xi_logo.png";
import logoMr from "../../images/logo_mr.svg";
let themeList = {
  "default": {
    navIcon: undefined,
    botIcon: icon.botIcWhite,
    sendIcon: icon.sendBtn,
    '--primary-color':'#854DFF',
    '--primary-color-30':'rgba(133, 77, 255, .30)',
    '--primary-color-18':'rgba(133, 77, 255, .18)',
    '--primary-color-70':'rgba(133, 77, 255, .7)',
    '--sub-color':'#854DFF',
    '--gradient-bg':'linear-gradient( 135deg, rgba(133, 77, 255, 1) 0, rgba(112, 98, 242, 1) 100% )',
    '--nav-bg':'#111111',
    '--nav-btn-text':'#FFFFFF',
    '--nav-input-bg':'rgba(255, 255, 255, 0.06)',
    '--nav-input-bd':'rgba(255, 255, 255, 0.06)',
    '--nav-input-icon':'#FFFFFF',
    '--nav-input-text':'#FFFFFF',
    '--nav-input-placeholder':'#FFFFFF',
    '--nav-text-date':'rgba(255, 255, 255, 0.62)',
    '--nav-text-main':'#FFFFFF',
    '--nav-text-sub':'#FFFFFF',
    '--usage-bg':'rgba(255, 255, 255, 0.1)',
    '--usage-divider':'rgba(255, 255, 255, 0.12)',
    '--power-color':'rgba(255, 255, 255, .38)',
    '--avater-bg':'#EBF4FF',
    '--avater-text':'#854DFF',
    '--card-bg':'linear-gradient(135deg, rgba(77.85, 187.1, 255, 1), rgba(133, 77, 255, 1), rgba(204, 77, 255, 1))',
    '--tag-bg':'rgba(47, 172, 100, 0.08)',
    '--tag-text':'#2FAC64',
  },
  "xingI": {
    navIcon: logo,
    botIcon: icon.xiIc,
    sendIcon: icon.sendBtn,
    '--primary-color':'#854DFF',
    '--primary-color-30':'rgba(133, 77, 255, .30)',
    '--primary-color-18':'rgba(133, 77, 255, .18)',
    '--primary-color-70':'rgba(133, 77, 255, .7)',
    '--sub-color':'#56606B',
    '--gradient-bg':'#756099',
    '--nav-bg':'#F1F2Fb',
    '--nav-btn-text':'#FFFFFF',
    '--nav-input-bg':'#FFFFFF',
    '--nav-input-bd':'#FFFFFF',
    '--nav-input-icon':'#7B7B7B',
    '--nav-input-text':'#7B7B7B',
    '--nav-input-placeholder':'#7B7B7B',
    '--nav-text-date':'#7B7B7B',
    '--nav-text-main':'#333333',
    '--nav-text-sub':'#7B7B7B',
    '--usage-bg':'rgba(255, 255, 255, 0.5)',
    '--usage-divider':'rgba(0, 0, 0, 0.12)',
    '--power-color':'#7B7B7B',
    '--avater-bg':'#9DABBA',
    '--avater-text':'#FFFFFF',
    '--card-bg':'linear-gradient(135deg, rgba(77.85, 187.1, 255, 1), rgba(133, 77, 255, 1), rgba(204, 77, 255, 1))',
    '--tag-bg':'#F2F2F3',
    '--tag-text':'#56606B',
  },
  'green_hld': {
    navIcon: undefined,
    botIcon: icon.botIcBlack,
    sendIcon: icon.sendBtn,
    GPT: 'green_hld',
    GPT4: 'orange_hld',
    '--primary-color':'#A6D6C9',
    '--primary-color-30':'rgba(166, 214, 200, .30)',
    '--primary-color-18':'rgba(166, 214, 200, .18)',
    '--primary-color-70':'rgba(166, 214, 200, .7)',
    '--sub-color':'#56606B',
    '--gradient-bg':'',
    '--nav-bg':'#F4FAF9',
    '--nav-btn-text':'#333333',
    '--nav-input-bg':'#56606B',
    '--nav-input-bd':'#56606B',
    '--nav-input-icon':'#FFFFFF',
    '--nav-input-text':'#FFFFFF',
    '--nav-input-placeholder':'#FFFFFF',
    '--nav-text-date':'#7B7B7B',
    '--nav-text-main':'#333333',
    '--nav-text-sub':'#7B7B7B',
    '--usage-bg':'#E9F1EF',
    '--usage-divider':'#E8E8E8',
    '--power-color':'#7B7B7B',
    '--avater-bg':'#9DABBA',
    '--avater-text':'#FFFFFF',
    '--card-bg':'linear-gradient(135deg, rgba(166, 214, 200, 1), rgba(232, 232, 232, 1))',
    '--tag-bg':'#F2F2F3',
    '--tag-text':'#56606B',
    '--font-family': '"Euclid Circular A", "YouYuan"',
  },
  'orange_hld': {
    navIcon: undefined,
    botIcon: icon.botIcBlack,
    sendIcon: icon.sendBtn,
    GPT: 'green_hld',
    GPT4: 'orange_hld',
    '--primary-color':'#ED691B',
    '--primary-color-30':'rgba(237, 105, 27, .30)',
    '--primary-color-18':'rgba(237, 105, 27, .18)',
    '--primary-color-70':'rgba(237, 105, 27, .7)',
    '--sub-color':'#ED691B',
    '--gradient-bg':'',
    '--nav-bg':'#F7F3F0',
    '--nav-btn-text':'#FFFFFF',
    '--nav-input-bg':'transparent',
    '--nav-input-bd':'#ED691B',
    '--nav-input-icon':'#7B7B7B',
    '--nav-input-text':'#333333',
    '--nav-input-placeholder':'#C8C8C8',
    '--nav-text-date':'#7B7B7B',
    '--nav-text-main':'#333333',
    '--nav-text-sub':'#7B7B7B',
    '--usage-bg':'#EEE8E5',
    '--usage-divider':'#E4DAD6',
    '--power-color':'#7B7B7B',
    '--avater-bg':'#FFE5D5',
    '--avater-text':'#ED691B',
    '--card-bg':'linear-gradient(135deg, rgba(237, 105, 27, 1), rgba(250, 184, 10, 1))',
    '--tag-bg':'#FFFAEC',
    '--tag-text':'#FAB90A',
    '--font-family': '"Euclid Circular A", "YouYuan"',
  },
  'blue_mr': {
    navIcon: logoMr,
    botIcon: icon.botIcWhite,
    sendIcon: icon.sendBtn,
    // GPT: 'green_hld',
    // GPT4: 'orange_hld',
    '--primary-color':'#E15D3E',
    '--primary-color-30':'rgba(225, 93, 62, .30)',
    '--primary-color-18':'rgba(225, 93, 62, .18)',
    '--primary-color-70':'rgba(225, 93, 62, .7)',
    '--sub-color':'#353EB0',
    '--gradient-bg':'',
    '--nav-bg':'#1D2348',
    '--nav-btn-text':'#FFFFFF',
    '--nav-input-bg':'rgba(255, 255, 255, 0.06)',
    '--nav-input-bd':'rgba(255, 255, 255, 0.06)',
    '--nav-input-icon':'#FFFFFF',
    '--nav-input-text':'#FFFFFF',
    '--nav-input-placeholder':'#FFFFFF',
    '--nav-text-date':'rgba(255, 255, 255, 0.62)',
    '--nav-text-main':'#FFFFFF',
    '--nav-text-sub':'#FFFFFF',
    '--usage-bg':'rgba(255, 255, 255, 0.1)',
    '--usage-divider':'rgba(255, 255, 255, 0.12)',
    '--power-color':'rgba(255, 255, 255, .38)',
    '--avater-bg':'#E6E8F5',
    '--avater-text':'#353EB0',
    '--card-bg':'linear-gradient(135deg, rgba(77.85, 187.1, 255, 1), rgba(133, 77, 255, 1), rgba(204, 77, 255, 1))',
    '--tag-bg':'rgba(225, 93, 62, .1)',
    '--tag-text':'#E15D3E',
  },
}

export default themeList;