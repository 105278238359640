import { SET_NAV_DATA, SET_USER_DATA, SET_PLAN_DATA, USER_LOG_OUT, SET_USAGE, SET_CHECK_USAGE, SET_CHAT_INFO, SET_THEME_DATA } from './actionTypes.js'

export const setNavData = (navData) => ({
  type: SET_NAV_DATA,
  navData: navData
})
export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  userData: userData
})
export const setPlanData = (planData) => ({
  type: SET_PLAN_DATA,
  planData: planData
})
export const userLogOut = (logData) => ({
  type: USER_LOG_OUT,
  logData: logData
})
export const setUsage = (usageData) => ({
  type: SET_USAGE,
  usageData: usageData
})
export const setCheckUsage = (callback) => ({
  type: SET_CHECK_USAGE,
  callback: callback
})
export const setChatInfo = (chatInfo) => ({
  type: SET_CHAT_INFO,
  chatInfo: chatInfo
})
export const setThemeData = (themeData) => ({
  type: SET_THEME_DATA,
  themeData: themeData
})
