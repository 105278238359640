import React from 'react'
import '../../style/component.less';
// import fetch from '../../fetch/axios';
// import { Radio } from 'antd';
import Info from '../../images/icon/info.svg';
import Check from '../../images/icon/check.svg';
import Logo from '../../images/logo_mr_block.png';

class Security extends React.Component {
  state = {

  }
  componentDidMount() {
 
  }

  componentDidUpdate() {
  }
  

  render() {
    const { t } = this.props;
    const { theme } = this.props.reducer;
    // console.log(theme)
    return (
      <div>
        {theme.themeName === 'blue_mr' &&
          <div className="security">
            <div className='s_logo'>
              <img src={Logo} alt=''/>
            </div>
            <div className='s_title'>
              <img src={Info} alt=''/>
              信息安全使用提示
            </div>
            <div className='s_body'>
              <div className='s_item'>
                <img src={Check} alt=''/>
                <div className='s_body_text'>禁止使用客户或Majorel的任何机密信息（例如培训材料、业务数据）;</div>
              </div>
              <div className='s_item'>
                <img src={Check} alt=''/>
                <div className='s_body_text'>禁止使用任何个人信息;</div>
              </div>
              <div className='s_item'>
                <img src={Check} alt=''/>
                <div className='s_body_text'>输出结果必须始终由人来验证其准确性；</div>
              </div>
              <div className='s_item'>
                <img src={Check} alt=''/>
                <div className='s_body_text'>不得含有颠覆国家政权、推翻社会主义制度，煽动分裂国家、破坏国家统一，宣扬恐怖主义、极端主义，宣扬民族仇恨、民族歧视，暴力、淫秽色情信息，虚假信息，以及可能扰乱经济秩序和社会秩序的内容;</div>
              </div>
              <div className='s_item'>
                <img src={Check} alt=''/>
                <div className='s_body_text'>使用者必须阅读、了解该工具使用条款和条件、使用政策等并确保严格遵守，避免侵权使用。该工具使用条款和条件详见《链接》;</div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default Security