import React from 'react'
import '../../style/component.less';
// import { Upload, message } from 'antd';
import { CloseOutlined, FileExcelOutlined, FilePdfOutlined, FileWordOutlined, FileGifOutlined, FileJpgOutlined, FileImageOutlined, LoadingOutlined, WarningOutlined, FileOutlined } from '@ant-design/icons';
import LazyImage from './lazyImage';
import RcViewer from '@hanyk/rc-viewer';

// <UploadList
//   fileList={this.state.uploadFileList}
//   onFileDelete = {(e)=>{ this.uploadRef.current.deleteFile(e) }}
//   model !== "list"
//   needRcViewer={true}
//   {...this.props}
// ></UploadList>

class UploadList extends React.Component {
  state = {
    guid: "",
    // uploadFileList: [],
    isDraging: false,
    hoverTpye: [ // 可点击的文件类型
      "image/jpg",
      "image/jpeg",
      "image/png",
      "image/gif",
    ],
  }

  componentDidMount() {
    this.setState({
      guid: this.getGuid(),
    });
  }

  getGuid() {
    return 'img_xxxxxxxx'.replace(/[xy]/g, function (c) {
      // eslint-disable-next-line
      let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      // eslint-disable-next-line
      return v.toString(16);
    });
  }

  // 格式化数字
  formatNumber(number) {
    if (number < 1000) {
      return number.toString(); // 如果小于1000，直接返回原数字
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + 'k'; // 转换成千，并保留1位小数
    } else {
      return (number / 1000000).toFixed(1) + 'M'; // 转换成百万，并保留1位小数
    }
  }

  showPath(e, id) {
    e.stopPropagation();
    let imgEle = document.querySelector(`.${this.state.guid}_${id} .cp_lz_img`);
    // console.log(imgEle);
    if (imgEle) imgEle.click();
  }

  render() {
    const { t, fileList, model, needRcViewer } = this.props;
    const { hoverTpye, guid } = this.state;

    const options = {
      // 是否显示下面工具栏 1 显示 0 隐藏
      // toolbar: 1,
      navbar: false,       //关闭缩略图
      fullscreen: false,   //播放全屏
      loop: false,         //是否循环 上一个 下一个
      minWidth:'',
      minHeight:'',
      toolbar: {
        zoomIn: { size: 'large' },  //放大
        zoomOut: { size: 'large' }, //缩小
        reset: { size: 'large' },   //重置
        prev: { show: true, size: 'large', }, //上一张
        play: { show: false, size: 'large', },  //播放
        next: { show: true, size: 'large', },  //下一张
        rotateLeft: { size: 'large' },  //左旋转
        rotateRight: { size: 'large' }, //右旋转
        flipHorizontal: { size: 'large' },  //左右翻转
        flipVertical: { size: 'large' },    //上下翻转
      },
    };

    const fileListElement = fileList.map((item, index)=>{
      if (hoverTpye.includes(item.type) && item.filePath && model === "list") {
        return (
          <LazyImage src={item.filePath} alt="" className={guid + "_" + index} key={index}></LazyImage>
        )
      }
      return (
        <div
          className={`cp_file ${item.status === "error" ? "upload_error" : ""} ${item.status === "done" && hoverTpye.includes(item.type) && item.filePath ? "can_click" : ""}`}
          key={index}
          onClick={(e)=>{ if (item.status === "done" && hoverTpye.includes(item.type) && item.filePath) this.showPath(e, index) }}
        >
          <div className='cp_file_lt'>
            <div className='cp_file_icon'>
              {item.status === "error" ? <WarningOutlined />
              : item.status === "uploading" ? <LoadingOutlined />
              : item.status === "done" ? 
                item.type === "application/msword" || item.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? <FileWordOutlined />
                :item.type === "application/pdf" ? <FilePdfOutlined />
                :item.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || item.type === "application/vnd.ms-excel" ? <FileExcelOutlined />
                :item.type === "image/jpg" || item.type === "image/jpeg" ? <FileJpgOutlined />
                :item.type === "image/gif" ? <FileGifOutlined />
                :item.type === "image/png" ? <FileImageOutlined />
                : <FileOutlined />
              : null}
            </div>
            {item.filePath && hoverTpye.includes(item.type) && <LazyImage src={item.filePath} alt="" className={guid + "_" + index}></LazyImage>}
          </div>
          <div className='cp_file_gt'>
            <div className='cp_file_name' title={item.name}>{item.name}</div>
            <div className='cp_file_bottom'>
              <div className='cp_file_type'>
                {item.type === "application/msword" ? "DOC"
                : item.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? "DOCX"
                : item.type === "application/pdf" ? "PDF"
                : item.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? "XLSX"
                : item.type === "application/vnd.ms-excel" ? "XLX"
                : item.type === "image/jpg" ? "JPG"
                : item.type === "image/jpeg" ? "JPEG"
                : item.type === "image/png" ? "PNG"
                : item.type === "image/gif" ? "GIF"
                : ""}
              </div>
              {item.tokens >= 0 ? 
                <div className='cp_file_size' title={item.tokens + " tokens"}>
                  {this.formatNumber(item.tokens)} tokens
                </div>
              : null}
            </div>
          </div>
          {item.status !== "uploading" && model !== "list" && <div className='cp_file_delete' onClick={()=>{ this.props.onFileDelete?.(item) }}>
            <CloseOutlined/>
          </div>}
        </div>
      )
    })

    return (
      <React.Fragment>
        {fileList?.length > 0 ?
          (needRcViewer ?
            <RcViewer
              options={options}
            // ref={(v) => {
            //   console.log(v);
            //   // setPreview(v);
            // }}
            >
              <div className={model === 'list' ? 'cp_chat_upload list_model' : 'cp_chat_upload'}>
                {fileListElement}
              </div>
            </RcViewer>
            :
            <div className={model === 'list' ? 'cp_chat_upload list_model' : 'cp_chat_upload'}>
              {fileListElement}
            </div>
          )
          : null
        }
      </React.Fragment>
    )
  }
}

export default UploadList