import React from 'react';
import '../../style/component.less';
import { FileImageOutlined, LoadingOutlined } from '@ant-design/icons';

class LazyImage extends React.Component {
  constructor(props) {
    super(props);
    this.imgRef = React.createRef();
    this.state = {
      imgVisible: false,
      isLoading: true,
      loaded: false, // 是否加载完成
      timer: null, // 定时器
    };
  }

  componentDidMount() {
    // 图片懒加载
    const root = document.querySelector('.cp_chat');
    // 当图片进入可视区域时加载图片
    // 问题：快速滚动时，有概率不触发
    const observer = new IntersectionObserver((entries) => {
      const image = entries[0];
      if (image.isIntersecting) {
        // console.log('image is visible', this.imgRef.current);
        this.setState({ imgVisible: true }, ()=>{
          this.checkLoaded();
        });
        observer.unobserve(image.target);
      }
    }, {
      root,
      threshold: 0.1
    });

    if (this.imgRef.current) {
      observer.observe(this.imgRef.current);
    }
  }

  componentWillUnmount() {
    const observer = this.observer;
    if (observer && this.imgRef.current) {
      observer.unobserve(this.imgRef.current);
    }
    // 清除定时器
    clearInterval(this.state.timer);
  }


  handleImageLoad = () => {
    // console.log('image loaded');
    this.setState({ isLoading: false }, () => {
      this.checkLoaded();
    });
  };

  // 检查图片是否加载完成
  checkLoaded = () => {
    let { isLoading, imgVisible, loaded } = this.state;
    if (!isLoading && imgVisible && !loaded) {
      this.setState({ loaded: true }, ()=>{
        // console.log('image loaded complete');
      });
      this.props.onLoaded?.(this.props.src);
    }
  }

  render() {
    const { src, alt } = this.props;
    const { imgVisible, isLoading } = this.state;

    return (
      <span className={`cp_lazyimg ${this.props.className}`} ref={this.imgRef}>
        {isLoading && (
          <span className='cp_lazyimg_loading'>
            <FileImageOutlined />
            <LoadingOutlined />
            <span className='cp_lazyimg_text'>Loading...</span>
          </span>
        )}
        <img
          src={imgVisible ? src : ""}
          alt={alt}
          onLoad={this.handleImageLoad}
          className={isLoading ? 'cp_lz_v cp_lz_img' : 'cp_lz_img'}
        />
      </span>
    );
  }
}

export default LazyImage;
