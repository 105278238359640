import React from 'react'
import '../../style/component.less';
import upLoadImage from '../../images/icon/upLoadImage.svg';
import { Upload, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import fetch from '../../fetch/axios';

const { Dragger } = Upload;

// uploadRef = React.createRef();
// <UploadChat
//   ref={this.uploadRef}
//   {...this.props}
//   guid={this.state.guid}
//   action={"https://aaa"} // 上传地址 可选 
//   getlist={(list) => { this.setState({ uploadFileList: list }) }}
// ></UploadChat>
// this.uploadRef.current.clearFileList();

class UploadChat extends React.Component {
  state = {
    guid: "",
    uploadFileList: [],
    isDraging: false, // 是否触发托拽视图
    openFileDialogOnClick: false, // 是否启用点击上传
  }

  componentDidMount() {
    window.addEventListener('dragend', this.onDragEnd.bind(this))
    window.addEventListener('mouseup', this.onDragEnd.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('dragend', this.onDragEnd.bind(this))
    window.removeEventListener('mouseup', this.onDragEnd.bind(this))
  }

  componentDidUpdate(prevProps) {
    if (this.props.guid !== prevProps.guid) {
      this.setState({
        guid: this.props.guid,
        uploadFileList: [],
      });
    }
  }

  // 检验项目文件数量
  uploadedFileNum() {
    let fNum = 0;
    this.state.uploadFileList.forEach((item)=>{
      if (item.status === "done") fNum+=1;
    })
    return fNum;
  }

  clearFileList() {
    this.setState({ uploadFileList: [] });
    this.props.getlist?.([]);
  }

  onDragStart(e) {
    if (!e.dataTransfer?.types?.includes('Files')) return this.onDragEnd();

    if (this.state.isDraging) return;
    
    this.setState({ isDraging: true });
    return;
  }
  onDragEnd(e) {
    this.setState({ isDraging: false });
  }

  deleteFile(e) {
    // 从数组中删除
    const { uploadFileList } = this.state;
    let index = uploadFileList.findIndex(item => item.uid === e.uid);
    if (index >=0 ) uploadFileList.splice(index, 1);
    this.props.getlist?.(uploadFileList);
    this.setState({ uploadFileList });
  }

  clickToUpload() {
    this.setState({ openFileDialogOnClick: true }, ()=>{
      let ele = document.querySelector('.cp_upload_chat_container');
      if (ele) ele.click();
      this.setState({openFileDialogOnClick: false})
    })
  }

  render() {
    const { t } = this.props;
    const _th = this;
    // const plan = this.props?.reducer?.plan || {};
    const { used } = this.props.reducer;
    // console.log(used)
    const uploadProps = {
      fileList: this.state.uploadFileList,
      maxCount: used.fileNum,
      name: 'file',
      multiple: true,
      accept: used?.fileString,
      action: this.props.action || `/api/Document/readDocument/${this.props.guid}`,
      headers: {
        Authorization: "Bearer "+localStorage.getItem('token')
      },
      beforeUpload(file) {
        const fileType = file.type;
        // console.log(fileType)
        if ( !used?.fileType?.includes(fileType) ) {
          message.warning(t('upload.warning 1', { fileString: used?.fileString?.replaceAll('.', '').replaceAll(',', '/').toUpperCase() }), 5);
          return Upload.LIST_IGNORE;
        }
        // // 判断文件大小 
        // console.log(file.size, plan.fileSize)
        // if ( file.size > plan.fileSize ) {
        //   message.warning(t("upload.warning 2"));
        //   return Upload.LIST_IGNORE;
        // }
        // // 判断文件数量
        // let fNum = _th.uploadedFileNum();
        // console.log(fNum, plan.fileNum);
        // if (fNum >= plan.fileNum) {
        //   message.warning(t("upload.warning 3"));
        //   return Upload.LIST_IGNORE;
        // }
        // return true;
      },
      onChange(info) {
        const { status } = info.file;
        // if (status !== 'uploading') {
        //   console.log(status)
        //   console.log(info.file, info.fileList);
        // }
        if (status === 'done') {
          message.success(`${info.file.name} ${t('upload.success 1')}`);
        } else if (status === 'error') {
          message.error(`${info.file.name} ${t('upload.error 1')}`);
        }
        info.fileList.forEach((item)=>{
          if (item.status === "done") {
            item.tokens = item.response?.data?.tokens;
            item.fileConent = item.response?.data?.fileConent;
            item.filePath = item.response?.data?.filePath || "";
          }
        })
        _th.setState({ uploadFileList: info.fileList });
        _th.props.getlist?.(info.fileList);
      },
      // onDrop(e) {
      //   console.log('Dropped files', e.dataTransfer.files);
      // },
      onRemove(file) {
        // console.log(file);
        // false 则不删除
        if (file.status === "error") {
          return true;
        } else if (file.status === 'done') {
          // 从接口删除
          console.log(file);
          ////////
          // let url_hush = file.response?.data?.url_hush;
          let fileId = file.response?.data?.fileid;
          fetch.post(`/api/document/deleteFile/${fileId}`).then(res=>{
            if (res&& res.success) {
              return true;
            } else {
              return false;
            }
          })
        } else if (file.status === 'uploading') {
          return false
        }
      }
    };

    return (
      <div className='cp_upload_chat' onDragEnter={(e)=>{ this.onDragStart(e) }}>
        {(used?.type === 'GPT_4_128' || used?.type === 'GPT_4_V') ?
          <Dragger {...uploadProps}
            openFileDialogOnClick={this.state.openFileDialogOnClick}
            onDrop={()=>{ this.onDragEnd() }}
            showUploadList={false}
            style={{
              border: "unset",
              background: "unset",
              boxShadow: "unset",
              padding: "0",
            }}
          >
            <div className='cp_upload_chat_container'>
              {this.props.children}
              {this.state.isDraging?
              //  onMouseLeave={()=>{ this.onDragEnd() }} onMouseUp={()=>{ this.onDragEnd() }}
                <div className='cp_upload_chat_drag'>
                  <img src={upLoadImage} alt='' />
                  <p className='cp_upload_chat_text'>{t('upload.drag in chat')}</p>
                  <CloseOutlined />
                </div>
                : null
              }
            </div>
          </Dragger>
          :
          this.props.children
        }
      </div>
    )
  }
}

export default UploadChat